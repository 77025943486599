import React from "react";
import { Container } from "react-bootstrap";
import Gallery from "../components/Gallery";

export default function Singlepage() {
  return (
    <>
      <Container className="text-center">
        <h2 style={{ paddingTop: "20px" }}>Showroom Cars</h2>
        <p>
          <b>Entdecken sie unsere Fahrzeuge für Ihre Ausbildung.</b>
          <br /> Wir bieten die bestmöglichen Vorrausetzungen für eine
          erfolgreiche Ausbildung.
        </p>
        <Gallery park="cars" />
      </Container>
    </>
  );
}
