import React from "react";
import { BlueHeadline, SectionBox, UpperText } from "./CustomStyled";

export default function PrivacyText() {
  return (
    <>
      <SectionBox>
        <BlueHeadline>Grundlegendes</BlueHeadline>
        <UpperText>
          Diese Datenschutzerklärung soll die Nutzer dieser Website über die
          Art, den Umfang und den Zweck der Erhebung und Verwendung
          personenbezogener Daten durch den Websitebetreiber "Fahrschule Andrè
          Lysk" informieren.
        </UpperText>
        <p>
          Der Websitebetreiber nimmt Ihren Datenschutz sehr ernst und behandelt
          Ihre personenbezogenen Daten vertraulich und entsprechend der
          gesetzlichen Vorschriften. Da durch neue Technologien und die ständige
          Weiterentwicklung dieser Webseite Änderungen an dieser
          Datenschutzerklärung vorgenommen werden können, empfehlen wir Ihnen
          sich die Datenschutzerklärung in regelmäßigen Abständen wieder
          durchzulesen.
        </p>
        <p>
          Definitionen der verwendeten Begriffe (z.B. “personenbezogene Daten”
          oder “Verarbeitung”) finden Sie in Art. 4 DSGVO.
        </p>
      </SectionBox>
      <hr />
      <SectionBox>
        <h2>Zugriffsdaten</h2>
        <p>
          Wir, der Websitebetreiber bzw. Seitenprovider, erheben aufgrund
          unseres berechtigten Interesses (s. Art. 6 Abs. 1 lit. f. DSGVO) Daten
          über Zugriffe auf die Website und speichern diese als
          „Server-Logfiles“ auf dem Server der Website ab. Folgende Daten werden
          so protokolliert:
        </p>
        <p>
          Besuchte Website
          <br />
          Uhrzeit zum Zeitpunkt des Zugriffes
          <br />
          Menge der gesendeten Daten in Byte
          <br />
          Quelle/Verweis, von welchem Sie auf die Seite gelangten
          <br />
          Verwendeter Browser
          <br />
          Verwendetes Betriebssystem
          <br />
          Verwendete IP-Adresse
        </p>
        <p>
          Die Server-Logfiles werden für maximal 7 Tage gespeichert und
          anschließend gelöscht. Die Speicherung der Daten erfolgt aus
          Sicherheitsgründen, um z. B. Missbrauchsfälle aufklären zu können.
          Müssen Daten aus Beweisgründen aufgehoben werden, sind sie solange von
          der Löschung ausgenommen bis der Vorfall endgültig geklärt ist.
        </p>
      </SectionBox>
      <SectionBox>
        <h2>Reichweitenmessung &amp; Cookies</h2>
        <p>
          Diese Website verwendet Cookies zur pseudonymisierten
          Reichweitenmessung, die entweder von unserem Server oder dem Server
          Dritter an den Browser des Nutzers übertragen werden. Bei Cookies
          handelt es sich um kleine Dateien, welche auf Ihrem Endgerät
          gespeichert werden. Ihr Browser greift auf diese Dateien zu. Durch den
          Einsatz von Cookies erhöht sich die Benutzerfreundlichkeit und
          Sicherheit dieser Website.
        </p>
        <p>
          Falls Sie nicht möchten, dass Cookies zur Reichweitenmessung auf Ihrem
          Endgerät gespeichert werden, können Sie dem Einsatz dieser Dateien
          hier widersprechen:
        </p>
        <ul>
          <li>
            Cookie-Deaktivierungsseite der Netzwerkwerbeinitiative:
            <a
              target="_blank"
              rel="noreferrer"
              href="http://optout.networkadvertising.org/?c=1#!/"
            >
              http://optout.networkadvertising.org/?c=1#!/
            </a>
          </li>
          <li>
            Cookie-Deaktivierungsseite der US-amerikanischen Website:
            <a
              target="_blank"
              rel="noreferrer"
              href="http://optout.aboutads.info/?c=2#!/"
            >
              http://optout.aboutads.info/?c=2#!/
            </a>
          </li>
          <li>
            Cookie-Deaktivierungsseite der europäischen Website:
            <a
              target="_blank"
              rel="noreferrer"
              href="http://optout.networkadvertising.org/?c=1#!/"
            >
              http://optout.networkadvertising.org/?c=1#!/
            </a>
          </li>
        </ul>
        <p>
          Gängige Browser bieten die Einstellungsoption, Cookies nicht
          zuzulassen. <br />
          <b>Hinweis:</b> Es ist nicht gewährleistet, dass Sie auf alle
          Funktionen dieser Website ohne Einschränkungen zugreifen können, wenn
          Sie entsprechende Einstellungen vornehmen.
        </p>
      </SectionBox>
      <hr />
      <SectionBox>
        <h2>Erfassung und Verarbeitung personenbezogener Daten</h2>
        <p>
          Der Websitebetreiber erhebt, nutzt und gibt Ihre personenbezogenen
          Daten nur dann weiter, wenn dies im gesetzlichen Rahmen erlaubt ist
          oder Sie in die Datenerhebung einwilligen.
        </p>
        <p>
          Als personenbezogene Daten gelten sämtliche Informationen, welche dazu
          dienen, Ihre Person zu bestimmen und welche zu Ihnen zurückverfolgt
          werden können – also beispielsweise Ihr Name, Ihre E-Mail-Adresse und
          Telefonnummer.
        </p>
        <p>
          Diese Website können Sie auch besuchen, ohne Angaben zu Ihrer Person
          zu machen. Zur Verbesserung unseres Online-Angebotes speichern wir
          jedoch (ohne Personenbezug) Ihre Zugriffsdaten auf diese Website. Zu
          diesen Zugriffsdaten gehören z. B. die von Ihnen angeforderte Datei
          oder der Name Ihres Internet-Providers. Durch die Anonymisierung der
          Daten sind Rückschlüsse auf Ihre Person nicht möglich.
        </p>
        <ul>
          <li>
            Wir verarbeiten personenbezogene Daten- hier E-Mail-Adresse - aus
            dem Kontaktformular.
          </li>
          <li>
            Wie? - Wir verarbeiten personenbezogene Daten nur nach
            ausdrücklicher Erlaubnis der betreffenden Nutzer und unter
            Einhaltung der geltenden Datenschutzbestimmungen.
          </li>
          <li>
            Warum? - Die Verarbeitung der personenbezogenen Daten erfolgt
            aufgrund unseres berechtigten Interesses zur Erfüllung unserer
            vertraglich vereinbarten Leistungen und zur Optimierung unseres
            Online-Angebotes.
          </li>
        </ul>
      </SectionBox>
      <hr />
      <SectionBox>
        <h2>Umgang mit Kontaktdaten</h2>
        <p>
          Nehmen Sie mit uns als Websitebetreiber durch die angebotenen
          Kontaktmöglichkeiten Verbindung auf, werden Ihre Angaben gespeichert,
          damit auf diese zur Bearbeitung und Beantwortung Ihrer Anfrage
          zurückgegriffen werden kann. Ohne Ihre Einwilligung werden diese Daten
          nicht an Dritte weitergegeben.
        </p>
      </SectionBox>
      <SectionBox>
        <h2>Rechte des Nutzers</h2>
        <p>
          Sie haben als Nutzer das Recht, auf Antrag eine kostenlose Auskunft
          darüber zu erhalten, welche personenbezogenen Daten über Sie
          gespeichert wurden. Sie haben außerdem das Recht auf Berichtigung
          falscher Daten und auf die Verarbeitungseinschränkung oder Löschung
          Ihrer personenbezogenen Daten. Falls zutreffend, können Sie auch Ihr
          Recht auf Datenportabilität geltend machen. Sollten Sie annehmen, dass
          Ihre Daten unrechtmäßig verarbeitet wurden, können Sie eine Beschwerde
          bei der zuständigen Aufsichtsbehörde einreichen.
        </p>
      </SectionBox>
      <SectionBox>
        <h2>Löschung von Daten</h2>
        <p>
          Sofern Ihr Wunsch nicht mit einer gesetzlichen Pflicht zur
          Aufbewahrung von Daten (z. B. Vorratsdatenspeicherung) kollidiert,
          haben Sie ein Anrecht auf Löschung Ihrer Daten. Von uns gespeicherte
          Daten werden, sollten sie für ihre Zweckbestimmung nicht mehr vonnöten
          sein und es keine gesetzlichen Aufbewahrungsfristen geben, gelöscht.
          Falls eine Löschung nicht durchgeführt werden kann, da die Daten für
          zulässige gesetzliche Zwecke erforderlich sind, erfolgt eine
          Einschränkung der Datenverarbeitung. In diesem Fall werden die Daten
          gesperrt und nicht für andere Zwecke verarbeitet.
        </p>
      </SectionBox>
      <SectionBox>
        <h2>Widerspruchsrecht</h2>
        <UpperText>
          Nutzer dieser Webseite können von ihrem Widerspruchsrecht Gebrauch
          machen und der Verarbeitung ihrer personenbezogenen Daten zu jeder
          Zeit widersprechen.
        </UpperText>
        <p>
          Wenn Sie eine Berichtigung, Sperrung, Löschung oder Auskunft über die
          zu Ihrer Person gespeicherten personenbezogenen Daten wünschen oder
          Fragen bzgl. der Erhebung, Verarbeitung oder Verwendung Ihrer
          personenbezogenen Daten haben oder erteilte Einwilligungen widerrufen
          möchten, wenden Sie sich bitte an folgende E-Mail-Adresse:&nbsp;
          <b>info(at)fahrschule-lysk.de *</b>
        </p>
      </SectionBox>
      <hr />
    </>
  );
}
