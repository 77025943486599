import React from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function LyFooter() {
  return (
    <footer className="py-4 mt-5 bg-light" id="bd-footer">
      <Container className="py-5">
        <Row className="d-flex justify-content-center">
          <div className="col-6 col-lg-3 mb-3">
            <h5>Easy Riding</h5>
            <p>
              Fahrschule Andrè Lysk
              <br />
              Sachsendamm 32
              <br />
              02943 Weißwasser
              <br />
              <br />
              <b>Tel:</b> 035 76 20 28 53
            </p>
          </div>
          <div className="col-6 col-lg-3 mb-3">
            <h5>Filiale Rietschen</h5>
            <p>
              Fahrschule Andrè Lysk
              <br />
              Görlitzer Str. 35
              <br />
              02956 Rietschen <br />
              <br />
              <b>Tel:</b> 035 772 40 243
            </p>
          </div>
          <div className="col-6 col-lg-3 mb-3">
            <h5>Themen</h5>
            <p>
              <Link to="/exam">Termine</Link>
              <br />
              <a href="/#bikesplay">Zweirad</a>
              <br />
              <a href="/#carplay">Ausbildung</a>
              <br />
              <Link to="/impress">Impressum</Link>
              <br />
              <Link to="/privacy">Datenschutz</Link>
            </p>
          </div>
          <div className="col-6 col-lg-3 mb-3">
            <h5>Öffnungszeiten</h5>
            <p>
              <b>Weißwasser</b>
              <br />
              Mo-Di und Do-Fr 16 - 18 Uhr
              <br />
              <br />
              <b>Rietschen</b>
              <br />
              Mittwoch 16 - 18 Uhr
            </p>
          </div>
        </Row>
      </Container>
    </footer>
  );
}
