import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Gallery from "../components/Gallery";

export default function Galerie() {
  let headline = "Motorradgarage";
  return (
    <>
      <Helmet>
        <title>Fuhrpark - Easy Riding</title>
        <meta name="description" content="Fahrschule Lysk E-Mail" />
      </Helmet>
      <Container className="text-center">
        <h2 style={{ paddingTop: "20px" }}>{headline}</h2>
        <p>
          <b>Entdecken sie unsere Fahrzeuge für Ihre Ausbildung.</b>
          <br /> Wir bieten die bestmöglichen Vorrausetzungen für eine
          erfolgreiche Ausbildung.
        </p>
        <Gallery park="bikes" />
      </Container>
    </>
  );
}
