import React from "react";

import Avatar from "./Avatar";

const avatars = [
  {
    name: "Andrè Lysk",
    phone: "0171 - 82 56 892",
  },
  {
    name: "Mirko Smers",
    phone: "0176 - 30 58 99 39",
  },
  {
    name: "Ulf Reimann",
    phone: "0171 - 37 35 550",
  },
  {
    name: "Jürgen Hanke",
    phone: "0171 - 34 32 500",
  },
  {
    name: "Frank Stuckatz",
    phone: "0163 - 79 81 614",
  },
  {
    name: "Gudrun Förster",
    phone: "0171 - 427 44 27",
  },
];

export default function Team() {
  return (
    <>
      <div id="ly-team" className="row">
        <div className="col">
          <h1 className="featurette-heading">Easy Riding Team!</h1>
          <p className="lead">
            In unserem Büro am Sachsendamm 32 in Weißwasser steht Ihnen Brit
            Lysk für Informationen und Anfragen zur Verfügung!
          </p>
          <div className="avatar-row">
            {avatars.map((item, index) => {
              return <Avatar key={index} name={item.name} phone={item.phone} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
}
