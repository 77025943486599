import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Headbox from "../components/Headbox";
import Imprint from "../components/Imprint";

export default function Imprintpage() {
  return (
    <>
      <Helmet>
        <title>Impressum - Easy Riding</title>
        <meta name="description" content="Fahrschule Lysk" />
      </Helmet>
      <Headbox />
      <Container>
        <Imprint />
      </Container>
    </>
  );
}
