import { React, useState } from "react";
import jquery from "jquery";

export default function Hello() {
  const [name, setName] = useState("");
  const [result, setResult] = useState("");

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const handleSumbit = (e) => {
    e.preventDefault();
    const form = jquery(e.target);
    jquery.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };

  return (
    <>
      <div className="container">
        <h1>{result}</h1>
        <form
          action="http://localhost:8000/hello.php"
          method="post"
          onSubmit={(event) => handleSumbit(event)}
        >
          <label htmlFor="name">Name: </label>
          <input
            type="text"
            id="name"
            name="name"
            className="form-control"
            value={name}
            onChange={(event) => handleChange(event)}
          />
          <br />
          <button className="btn btn-primary" type="submit">
            Submit
          </button>
        </form>
      </div>
    </>
  );
}
