import "./App.css";
import Bsnavbar from "./components/Bsnavbar";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import { Helmet } from "react-helmet";

import Home from "./pages/Home";
import Services from "./pages/Services";
import Cars from "./pages/Cars";
import Start from "./pages/Start";
import LyFooter from "./components/LyFooter";
import Galerie from "./pages/Galerie";
import Privacy from "./pages/Privacy";
import Imprintpage from "./pages/Imprintpage";
import Singlepage from "./pages/Singlepage";
import Error from "./pages/Error";
import Hello from "./pages/Hello";

function App() {
  return (
    <>
      <Helmet>
        <title>Easy Riding</title>
      </Helmet>
      <Bsnavbar />

      <Routes>
        {/* pass element in JSX with self closing Route -- important */}
        <Route path="/home" element={<Home />} />
        <Route path="/bikes" element={<Galerie />} />
        <Route path="/" element={<Start />} />
        <Route path="/cars" element={<Singlepage />} />
        <Route path="/services" element={<Services />} />
        <Route path="/exam" element={<Cars />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/impress" element={<Imprintpage />} />
        <Route path="/hello" element={<Hello />} />
        <Route path="*" element={<Error />} />
      </Routes>

      <LyFooter />
      <CookieConsent
        location="bottom"
        buttonText="Geht klar!"
        cookieName="fahrschulCookieLysk"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={365}
      >
        Die Website benutzt Cookies, für ein besseres Benutzererlebnis.{" "}
        <a href="/privacy">Datenschutzerklärung</a>{" "}
      </CookieConsent>
    </>
  );
}

export default App;
