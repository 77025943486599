import React from "react";
import { Container } from "react-bootstrap";

import ContentCard from "../components/ContentCard";
import Headbox from "../components/Headbox";

export default function Services() {
  return (
    <>
      <Headbox />
      <Container>
        <ContentCard />
      </Container>
    </>
  );
}
