import React from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
export default function Bsnavbar() {
  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand href="/">Easy Riding</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="/#carplay">Fahrschule</Nav.Link>
              <NavDropdown
                title="Fuhrpark"
                id="basic-nav-dropdown"
                align="end"
                menuVariant="dark"
              >
                <NavDropdown.Item href="/bikes">
                  Motorrad-Garage
                </NavDropdown.Item>
                <NavDropdown.Item href="/cars">Auto Fuhrpark</NavDropdown.Item>
                <NavDropdown.Item href="/privacy">
                  Datenschutzerklärung
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/impress">Impressum</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/exam">Termine</Nav.Link>
              <Nav.Link href="/services">Leistungen</Nav.Link>

              <Nav.Link href="/home">Kontakt</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
