import React from "react";

import Headbox from "../components/Headbox";
import Events from "../components/Events";

export default function Cars() {
  let eventEditDate = new Date();
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  const formatDate = eventEditDate.toLocaleDateString("de-DE", options);
  return (
    <>
      <Headbox />
      <div className="container" style={{ marginTop: "30px" }}>
        <div style={{ textAlign: "center" }}>
          <h2>Termine Theorieprüfung</h2>
          <p>Zeiten bitte in der Fahrschule erfragen!</p>
        </div>
        <Events />
        <p style={{ textAlign: "center", fontWeight: "bold" }}>
          Letzte Aktualisierung {formatDate}
        </p>
      </div>
    </>
  );
}
