import React from "react";
import { Link } from "react-router-dom";
import Headbox from "../components/Headbox";

export default function Error() {
  return (
    <>
      <Headbox />
      <div
        className="container"
        style={{ marginTop: "100px", marginBottom: "150px" }}
      >
        <div style={{ textAlign: "center" }}>
          <h2>404 Page Not Found</h2>
          <p>Diese Seite existiert nicht!!</p>
          <Link to="/">Zur Startseite</Link>
        </div>
      </div>
    </>
  );
}
