import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";

import PrivacyText from "../components/PrivacyText";

// import { useParams } from "react-router-dom";

const PrivacyBox = styled.div`
  text-align: center;
  margin-top: 20px;
`;

export default function Privacy() {
  // let { privacyId } = useParams();
  // console.log(privacyId);
  return (
    <>
      <Container>
        <PrivacyBox>
          <h1>Datenschutzerklärung (Privacy)</h1>
          <p>Stand 08/2022</p>
        </PrivacyBox>
        <PrivacyText />
      </Container>
    </>
  );
}
