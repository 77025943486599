import React from "react";
import $ from "jquery";
import { Helmet } from "react-helmet";

import { useState } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Home() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [messg, setMessg] = useState("");
  const [result, setResult] = useState("");
  const [sent, setSent] = useState(true);

  const handleChange = (e) => {
    setName(e.target.value);
  };
  const handleChangeMsg = (e) => {
    setMessg(e.target.value);
  };
  const handleChangeMail = (e) => {
    setEmail(e.target.value);
  };

  const handleSumbit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
        setSent(false);
      },
    });
  };

  const url = "https://app.fahrschule-lysk.de/server/server.php";

  return (
    <>
      <Helmet>
        <title>Kontakt - Easy Riding</title>
        <meta name="description" content="Fahrschule Lysk E-Mail" />
      </Helmet>
      <div className="audicars"></div>
      <Container>
        <div className="App" style={{ paddingTop: "30px" }}>
          <div className="form-box">
            <h2 style={{ paddingBottom: "0" }}>Kontakt</h2>
            <hr />
            {result && (
              <>
                <div className="alert alert-success">{result}</div>
                <p>
                  <Link to="/">Weiter</Link>
                </p>
              </>
            )}
            {sent && (
              <>
                <div className="alert alert-info">Bitte alles ausfüllen!</div>
                <form
                  action={url}
                  method="post"
                  onSubmit={(event) => handleSumbit(event)}
                >
                  <label htmlFor="name">Name: </label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    id="name"
                    name="name"
                    value={name}
                    onChange={(event) => handleChange(event)}
                  />
                  <br />
                  <label htmlFor="name">Ihre E-Mail: </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(event) => handleChangeMail(event)}
                  />
                  <br />
                  <label htmlFor="betreff">Betreff: </label>
                  <select className="form-control" name="betreff" id="betreff">
                    <option>Allgemeine Anfrage</option>
                    <option>Theorieprüfung</option>
                    <option>Anmeldung</option>
                  </select>
                  <br />
                  <label htmlFor="message">Nachricht: </label>
                  <textarea
                    className="form-control"
                    name="message"
                    required
                    id="message"
                    value={messg}
                    onChange={(event) => handleChangeMsg(event)}
                  />
                  <br />
                  <button type="submit" className="btn btn-warning">
                    Mail senden
                  </button>
                </form>
              </>
            )}
          </div>
        </div>
      </Container>
    </>
  );
}
