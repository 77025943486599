import React from "react";

import CardData from "../data/cards.json";

export default function LayoutCard(props) {
  const cardnumber = parseInt(props.cardid);

  return (
    <>
      <div
        id={props.hrid}
        className={cardnumber % 2 === 0 ? "row" : "row rev"}
        style={{ marginTop: "30px" }}
      >
        <div className="col-md-7">
          <h2 className="featurette-heading">
            {CardData.cards[cardnumber].headline}
          </h2>
          <p className="lead">{CardData.cards[cardnumber].textblock}</p>
          {CardData.cards[cardnumber].more && (
            <a
              className="btn btn-primary"
              href={CardData.cards[cardnumber].more}
            >
              Jetzt entdecken!
            </a>
          )}
        </div>
        <div className="col-md-5">
          <img
            className={cardnumber === 2 ? "img-fluid rounded" : "img-fluid"}
            src={CardData.cards[cardnumber].imgfile}
            alt="Audi"
          />
        </div>
      </div>
      <hr />
    </>
  );
}
