import React from "react";
import { useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

const CalSheet = styled.div`
  text-align: center;
  width: 120px;
  margin: 20px auto;

  border: 1px solid #999;
  padding-top: 0px;

  border-radius: 7px;
  box-shadow: 2px 0 7px #ccc;
`;

const RedHead = styled.div`
  background: red;
  color: #fff;
  padding: 8px 0;
  border-radius: 7px 7px 0 0;
`;

const SheetBody = styled.div`
  & h1 {
    font-size: 60px;
    margin: 0;
  }
`;

const Clearing = styled.div`
  clear: both;
`;

export default function Events() {
  const baseURL = "https://app.fahrschule-lysk.de/server/events.php";
  const [post, setPost] = React.useState(null);

  useEffect(() => {
    axios.get(baseURL).then((response) => {
      setPost(response.data);
      console.log(response.data);
    });
  }, []);

  console.log(post);
  if (!post) return null;

  return (
    <>
      <div className="d-flex justify-content-center">
        <div className="row">
          {post.map((post, index) => {
            return (
              <div key={index} className="col">
                <CalSheet>
                  <RedHead>
                    <b>{post.mon}</b>
                  </RedHead>
                  <SheetBody>
                    <h1>{post.day}</h1>
                  </SheetBody>
                  <p>Prüfung</p>
                </CalSheet>
              </div>
            );
          })}
        </div>
      </div>
      <Clearing></Clearing>
    </>
  );
}
