import React from "react";
import Ausweis from "../img/drivers-license.png";
import "./ContentCard.css";
import styled from "styled-components";
import { Table } from "react-bootstrap";

const ImageBox = styled.div`
  margin-top: 10%;
`;

export default function ContentCard() {
  return (
    <>
      <div className="row">
        <div className="col">
          <h2 className="featurette-heading">Unsere Leistungen</h2>
          <p>
            <a className="shortlink" href="#antrag">
              Benötigte Unterlagen zum Führerscheinantrag
            </a>
          </p>
          <ul>
            <li>PKW und Kradausbildung</li>
            <li>Ausbildungsbeginn: sofort!</li>
            <li>Intensivkurse (z.B. in den Ferien)</li>
            <li>Ausbildung auf Automatik- Fahrzeug / Behindertenfahrzeug</li>
            <li>Erste Hilfe</li>
            <li>Auffrischung Theorie / Praxis</li>
            <li>Verkehrsteilnehmerschulung</li>
            <li>Vorbereitungskurse zur Wiedererteilung</li>
          </ul>
        </div>
      </div>
      <hr id="antrag" />
      <div className="row">
        <div className="col">
          <h2 className="featurette-heading">Führerscheinantrag</h2>
          <h4 style={{ color: "darkred" }}>Benötigte Unterlagen</h4>
          <ul>
            <li>1 Paßbild (kein Automatenbild)</li>
            <li>Erste Hilfe Nachweis</li>
            <li>Sehtest (Optiker)</li>
            <li>Antrag mit Stempel der Fahrschule</li>
          </ul>

          <p>
            Der Antrag ist persönlich in der Führerscheinstelle abzugeben. Wenn
            das 18. Lebensjahr noch nicht erreicht ist, muss ein
            Erziehungsberechtigter den Antrag für die Antragstellung in Niesky
            unterschrieben haben.
          </p>
        </div>
        <div className="col">
          <ImageBox>
            <img src={Ausweis} alt="Führerschein" />
          </ImageBox>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <p>
            <b>
              Die Anträge werden in der Führerscheinstelle in Niesky zu
              folgenden Zeiten angenommen:
            </b>
          </p>
          <div style={{ maxWidth: "500px" }}>
            <Table striped>
              <tbody>
                <tr>
                  <td>Montag</td>
                  <td>8.30 - 12.00 Uhr</td>
                </tr>
                <tr>
                  <td>Dienstag</td>
                  <td>8.30 - 12.00 Uhr und 14.00 - 18.00 Uhr</td>
                </tr>
                <tr>
                  <td>Mittwoch</td>
                  <td>geschlossen</td>
                </tr>
                <tr>
                  <td>Donnerstag</td>
                  <td>8.30 - 12.00 Uhr und 14.00 - 18.00 Uhr</td>
                </tr>
                <tr>
                  <td>Freitag</td>
                  <td>8.30 - 12.00 Uhr</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
}
