/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useState } from "react";

import cars from "../data/carpark.json";
import bikes from "../data/motorbikes.json";

import "./Gallery.css";

export default function Gallery(props) {
  const [model, setModel] = useState(false);
  const [tmpImgSrc, setTempImgSrc] = useState("");
  const [imgCaption, setImgCaption] = useState("");
  const getImg = (imgSrc, imgCapt) => {
    //console.warn(imgSrc);
    setTempImgSrc(imgSrc);
    setModel(true);
    setImgCaption(imgCapt);
  };

  let gal = props.park === "cars" ? cars : bikes;

  return (
    <>
      <div className={model ? "model open" : "model"}>
        <button
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          onClick={() => setModel(false)}
        ></button>
        <img src={tmpImgSrc} />
        <div className="i-caption">{imgCaption}</div>
      </div>
      <div className="gallery">
        {gal.map((item, index) => {
          return (
            <div
              className="pics"
              key={index}
              onClick={() => getImg(item.imgSrc, item.capt)}
            >
              <img src={item.imgSrc} style={{ width: "100%" }} />
            </div>
          );
        })}
      </div>
    </>
  );
}
