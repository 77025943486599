import React from "react";
import { SectionBox, BoldText, BlueHeadline } from "./CustomStyled";

export default function Imprint() {
  return (
    <>
      <SectionBox>
        <BlueHeadline>Impressum</BlueHeadline>
        <p style={{ fontWeight: "bold" }}>
          Sachsendamm 32<br></br>
          02943 Weißwasser
        </p>
        <p>
          Telefon: 035 76 – 20 28 53 <br></br>Telefax: 035 76 – 40 444 97
        </p>
      </SectionBox>
      <SectionBox>
        <h2>Haftungsausschluss und Copyright</h2>
        <p>
          Unsere Internetseiten bieten Ihnen eine Vielzahl von Informationen und
          wurden mit größter Sorgfalt erstellt. Wir übernehmen keine Haftung
          oder Garantie für die Aktualität, Richtigkeit und Vollständigkeit der
          zur Verfügung gestellten Informationen.
        </p>
        <p>
          Sie können unsere Informationen speichern und Verknüpfungen zu unseren
          Seiten einrichten. Bei Verlinkung von kommerziellen Anbietern müssen
          unsere Seiten alleiniger Bestandteil des Navigator-Fensters sein. Die
          Informationen dürfen nicht verändert oder verfälscht werden. Wir
          behalten uns vor, Informationen jederzeit ohne vorherige Ankündigung
          zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung
          einzustellen.
        </p>
      </SectionBox>
      <SectionBox>
        <h2>Links auf Web-Sites Dritter</h2>
        <p>
          Die veröffentlichten Links werden mit größtmöglicher Sorgfalt
          recherchiert und zusammengestellt. Die Redaktion hat keinen Einfluss
          auf die aktuelle und zukünftige Gestaltung und Inhalte der verlinkten
          Seiten. Der Urheber ist nicht für den Inhalt der verknüpften Seiten
          verantwortlich und macht sich den Inhalt nicht zu eigen. Für illegale,
          fehlerhafte oder unvollständige Inhalte sowie für Schäden, die durch
          die Nutzung oder Nichtnutzung der Informationen entstehen, haftet
          allein der Anbieter der Web-Site, auf die verwiesen wurde. Die Haftung
          desjenigen, der lediglich auf die Veröffentlichung durch einen Link
          hinweist, ist ausgeschlossen. Für fremde Hinweise ist der Urheber nur
          dann verantwortlich, wenn sie von ihnen, d.h. auch von einem
          eventuellen rechtswidrigen bzw. strafbaren Inhalt, positive Kenntnis
          hat, und es technisch möglich und zumutbar ist, deren Nutzung zu
          verhindern.
        </p>
      </SectionBox>
      <SectionBox>
        <h2>Fahrschulerlaubnis</h2>
        <BoldText>
          Regierungspräsidium Dresden<br></br> Stauffenbergallee 2<br></br>{" "}
          01099 Dresden
        </BoldText>
        <BoldText>
          Az (Bei Antwortschreiben und Zahlung angeben) <br></br>
          45D-3854_01/84/Lysk
        </BoldText>
        <p>
          erhält gem. § 10-13 des Gesetzes äber das Fahrlehrerwesen (FahrlG:vom
          25.08.1969 (BGB. 1S 1336)) in der derzeitig gültigen Fassung die
          Erlaubnis in 02943 Weißwasser,Sachsendamm 32 eine Fahrschule in den
          Klassen A,BE zu betreiben. Die Ausbildung anderer Klassen ist
          unzulässlig.
        </p>
        <BoldText>Nebenbestimmungen:</BoldText>
        <p>
          Die Höchstzahl der Schüler, die im Unterricht gleichzeitig
          unterrichtet werden dürfen, wird auf 20 begrenzt. Der Inhaber hat
          dafür Sorge zu tragen, dass für jeden Fahrschüler mindestens eine
          Sitzgelegenheit mit Rückenlehne und eine Schreibunterlage
          (Mindestgröße DIN A4) vorhanden sein. Im Falle der Beschädigung eines
          angestellten Fahrlehrers verringert sich die maximale Schüleranzahl
          entsprechend §23 Abs.4 Arbeitsstättenverordnung – BGBl. 1975 l. S. 729
          -, zuletzt geändert durch Artikel 4 der Verordnung vom 04.12.1996 –
          BGBl. l. S. i841.
        </p>
        <p>
          Die Höchstzahl der Schüler, die im Unterricht gleichzeitig
          unterrichtet werden dürfen,wird auf 20 begrenzt. Der Inhaber hat dafür
          Sorge zu tragen, dass für jeden Fahrschüler mindestens eine
          Sitzgelegenheit mit Rückenlehne und eine Schreibunterlage
          (Mindestgröße DIN A4) vorhanden sein. Im Falle der Beschädigung eines
          angestellten Fahrlehrers verringert sich die maximale Schüleranzahl
          entsprechend §23 Abs.4 Arbeitsstättenverordnung – BGBl. 1975 l. S. 729
          -, zuletzt geändert durch Artikel 4 der Verordnung vom 04.12.1996 –
          BGBl. l. S. i841.
        </p>
        <BoldText>
          Bis zum 10.04.2005 ist die ungültig gewordene Erlaubnisurkunde zu
          übersenden.
        </BoldText>
        <p>
          Für den Betrieb der Fahrschule gelten die allgemeinen Vorschriften des
          Fahrlehrergesetzes und die hierzu ergangenen Rechtsverordnungenund
          Anordnungen. Es werden auf die für den Fahrschulinhaber geltenden
          allgemeinen Pflichten sowie insbesondere auf die Anzeige- und
          Aufzeichnungspflichten hingewiesen. Die Vorschriften über Lehr-und
          Prüffahrzeuge, über die Unerrichtsentgelten und über das Ruhen und
          Erlöschen der Erlaubnis usw. sind ausnahmslos zu beachten.
        </p>
      </SectionBox>
    </>
  );
}
