import React from "react";
import { Carousel } from "react-bootstrap";
import audi from "../img/slider-lysk-1.jpg";
import ktmbike from "../img/slider-react-2.jpg";
import suv from "../img/slider-react-3.jpg";
import school from "../img/slider-school-room.jpg";

export default function Slider() {
  return (
    <Carousel>
      <Carousel.Item>
        <img className="d-block w-100" src={audi} alt="Audi A4 Avant" />
        <Carousel.Caption>
          <h3 className="slide-cap">Easy Riding</h3>
          <p className="slider-p">Fahrschule Andrè Lysk</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={ktmbike} alt="KTM Klasse A" />

        <Carousel.Caption>
          <h3 className="slide-cap">Easy Riding</h3>
          <p className="slider-p">ANDRÈ LYSK</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={school} alt="Schulungsraum" />

        <Carousel.Caption>
          <h3 className="slide-cap">Easy Riding</h3>
          <p className="slider-p p-wide">
            Freundlich, zielstrebig und kompetent
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={suv} alt="VW Tiguan" />

        <Carousel.Caption>
          <h3 className="slide-cap">Easy Riding</h3>
          <p className="slider-p p-wide">
            Freundlich, zielstrebig und kompetent
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}
