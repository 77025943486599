import React from "react";
import data from "../data/actors..json";
import { Container } from "react-bootstrap";
import LayoutCard from "../components/LayoutCard";
import Slider from "../components/Slider";
import Team from "../components/Team";

export default function Start() {
  console.log(data.actors[2].lastname);
  return (
    <>
      <div className="frontslider">
        <Slider />
      </div>
      <Container>
        <LayoutCard hrid="bikesplay" cardid="0" />
        <LayoutCard hrid="carplay" cardid="1" />

        <Team />
      </Container>
    </>
  );
}
