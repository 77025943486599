import React from "react";
import avatarImg from "../img/avatar-lysk.jpeg";
import styled from "styled-components";

const BlueName = styled.p`
  color: #d30e52;

  font-weight: bold;
  font-size: 140%;
  margin-bottom: 0;
  & a {
    text-decoration: none;
    color: #d30e52;
  }
`;

const PhoneNum = styled.p`
  color: #333;
  font-weight: bold;
  font-size: 110%;
  & a {
    text-decoration: none;
    color: #333;
  }
`;

export default function Avatar(props) {
  const phoneURL = `tel:+49-${props.phone}`;

  const phoneInfo = () => {
    alert("Klicke meinen Namen oder die Nummer, um zu telefonieren!");
  };
  return (
    <>
      <div className="avatar-box">
        <img
          onClick={phoneInfo}
          src={avatarImg}
          className="avatar rounded"
          alt="Lysk-Avatar"
        />
        <BlueName>
          <a href={phoneURL}>{props.name}</a>
        </BlueName>
        <PhoneNum>
          <a href={phoneURL}>{props.phone}</a>
        </PhoneNum>
      </div>
    </>
  );
}
