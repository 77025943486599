import styled from "styled-components";

export const SectionBox = styled.section`
  text-align: left;
  margin-top: 20px;
`;

export const BoldText = styled.p`
  font-weight: bold;
`;

export const UpperText = styled.p`
  text-transform: uppercase;
`;

export const JustifiedText = styled.p`
  text-align: justify;
`;
export const BlueHeadline = styled.h2`
  color: rgb(59, 134, 214);
`;
